var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form.attendingInputs.length > -1)?_c('form',{staticClass:"form"},[_vm._l((_vm.form.attendingInputs),function(input,i){return [(_vm.form.fields)?[(
          _vm.form.fields[i] &&
          (input.type === 'text' || input.type === 'file') &&
          _vm.fieldDependency(_vm.form.fields[i])
        )?_c('Input',{key:i,attrs:{"config":input},model:{value:(_vm.form.fields[i].value),callback:function ($$v) {_vm.$set(_vm.form.fields[i], "value", $$v)},expression:"form.fields[i].value"}}):_vm._e(),(_vm.form.fields[i] && input.type === 'textarea' && _vm.fieldDependency(_vm.form.fields[i]))?_c('Textarea',{key:i,attrs:{"config":input},model:{value:(_vm.form.fields[i].value),callback:function ($$v) {_vm.$set(_vm.form.fields[i], "value", $$v)},expression:"form.fields[i].value"}}):_vm._e(),_vm._v(" "),(_vm.form.fields[i] && input.type === 'checkbox' && _vm.fieldDependency(_vm.form.fields[i]))?_c('Checkboxes',{key:i,attrs:{"config":input},model:{value:(_vm.form.fields[i].value),callback:function ($$v) {_vm.$set(_vm.form.fields[i], "value", $$v)},expression:"form.fields[i].value"}}):_vm._e(),(_vm.form.fields[i] && input.type === 'radio' && _vm.fieldDependency(_vm.form.fields[i]))?_c('Radios',{key:i,attrs:{"config":input},model:{value:(_vm.form.fields[i].value),callback:function ($$v) {_vm.$set(_vm.form.fields[i], "value", $$v)},expression:"form.fields[i].value"}}):_vm._e(),(_vm.form.fields[i] && input.type === 'select' && _vm.fieldDependency(_vm.form.fields[i]))?_c('Select',{key:i,attrs:{"config":input},model:{value:(_vm.form.fields[i].value),callback:function ($$v) {_vm.$set(_vm.form.fields[i], "value", $$v)},expression:"form.fields[i].value"}}):_vm._e(),(
          _vm.form.fields[i] &&
          (input.type === 'description' || input.type === 'url' || input.type === 'label') &&
          _vm.fieldDependency(_vm.form.fields[i])
        )?_c('Description',{key:i,attrs:{"config":input}}):_vm._e()]:_vm._e()]}),_c('Modal',{staticClass:"cropper-modal",attrs:{"config":_vm.form.modal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"title is-4"},[_vm._v(" "+_vm._s(_vm.translations.exhibitors.onboarding.managedFields.crop_image)+" ")])]},proxy:true},{key:"footer",fn:function(){return [(_vm.form.confirming)?[_c('div',{staticClass:"buttons",staticStyle:{"width":"100%"}},[_c('button',{staticClass:"button is-success is-pulled-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.confirmCrop()}}},[_vm._v(" "+_vm._s(_vm.translations.common.confirm)+" ")]),_c('button',{staticClass:"button is-light",attrs:{"type":"button"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.translations.common.cancel)+" ")]),_c('button',{staticClass:"button is-light",attrs:{"type":"button"},on:{"click":function($event){return _vm.cancelCrop()}}},[_vm._v(" "+_vm._s(_vm.translations.exhibitors.onboarding.managedFields.crop_again)+" ")])])]:_vm._e(),(_vm.form.cropping)?[_c('button',{staticClass:"button is-light",attrs:{"type":"button"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.translations.common.cancel)+" ")])]:_vm._e()]},proxy:true}],null,false,2303480823)},[(_vm.form.cropping)?[_c('Cropper',{attrs:{"config":_vm.form.cropper}})]:_vm._e(),(_vm.form.confirming)?[_c('div',{staticClass:"figure"},[_c('img',{attrs:{"src":_vm.form.cropSrc,"alt":"","loading":"lazy"}})])]:_vm._e()],2)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }