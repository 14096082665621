


























































































































































// @ is an alias to /src

import { defineComponent, reactive } from '@vue/composition-api';
import { MutationPayload } from 'vuex';
import Radios from '@/components/form/radios.vue';
import Modal from '@/components/modal.vue';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Checkbox from '@/components/form/checkbox.vue';
import { ITicketType } from '@/models/ticket';
import { newTicketAssignment } from '@/api/tickets';
import router from '@/router';
import store from '@/store';
import { basicInputs, inputs, modal, preForm } from '../index';
import RegistrationForm from '@/partials/RegistrationForm.vue';
import { uploadFile } from '@/api/files';
import { getVisitors } from '@/api/visitors';
import useContext, { IUseContext } from '@/composition/context';
import useForm from '@/composition/form';
import { ICheckbox } from '@/components/form';
import { getPackages } from '@/api/events';
import { IPackage } from '@/models/event';
import { createExhibitorInvitation } from '@/api/exhibitors';
import { IVisitor } from '@/models/visitor';

export default defineComponent({
  components: {
    Modal,
    Input,
    Radios,
    Select,
    Checkbox,
    RegistrationForm,
  },
  setup() {
    const { contextEvent, translations } = useContext() as unknown as IUseContext;

    const { fieldDependency, buildForm, disabled, form, validateForm } = useForm();

    const state = reactive({
      loading: false,
      modal: { ...modal },
      ticketType: {} as ITicketType,
      basicInputs: basicInputs(),
      packageInputs: [] as ICheckbox[],
      preInputs: inputs(),
      event: 0,
      exhibitor: 0,
      step: 0,
      packages: [] as IPackage[],
      packagesAdded: [] as number[],
      userExists: false,
      visitor: {} as IVisitor,
    });

    const basicForm = {
      name: '',
      email: '',
      event: 0,
    };

    const assignTicket = async () => {
      state.loading = true;
      const event = parseInt(router.currentRoute.params.event, 10);
      const exhibitor = parseInt(router.currentRoute.params.exhibitor, 10);

      newTicketAssignment({
        assignee_contact:
          preForm.type_of_message === 'email' ? preForm.email : `${preForm.phone_nr}`,
        assignee_name: 'Name',
        ticket_type: parseInt(preForm.ticket_type, 10),
        capacity: preForm.nr_of_tickets,
        event,
        exhibitor,
      })
        .then(() => {
          state.loading = false;
          router.push(`/event/${event}/manage-exhibitor/${exhibitor}/visitors`);
          store.commit('addPopup', {
            message: translations.value.exhibitors.tickets.ticket_success,
            type: 'success',
            autohide: true,
          });
        })
        .catch((err) => {
          state.loading = false;
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: err.nessage,
              type: 'danger',
              autohide: true,
            });
          }
        });
    };

    const assignAnother = () => {
      state.step = state.step - state.step;
      buildPackagesTable();
    };

    const checkVisitor = () => {
      const event = parseInt(router.currentRoute.params.event, 10);
      state.loading = true;
      getVisitors({ email: preForm.email, event }).then((response) => {
        if (response.data.results.length > 0) {
          state.visitor = response.data.results[0];
          state.userExists = true;
          state.step = state.step + 2;
        } else {
          state.step = state.step + 1;
          basicForm.email = preForm.email;
          state.basicInputs.email.value = preForm.email;
        }
        state.loading = false;
      });
    };

    const buildPackagesTable = async () => {
      const packagesResponse = await getPackages({ event, exhibitor });
      state.packages = packagesResponse.data.results;
      if (state.packages.length > 0) {
        state.packages.forEach((packageItem, i) => {
          const index = i;
          state.packageInputs.push({
            id: `package-checkbox-${packageItem.id}`,
            name: `package_checkbox_${packageItem.id}`,
            value: false,
            onClick: (value: 'on' | 'off') => {
              handlePackageChange(value, index);
            },
          });
        });
      } else {
        state.step = 4;
      }
    };

    const submit = async (callback?: () => void) => {
      validateForm();
      state.loading = true;
      if (!form.invalid || state.userExists) {
        if (!state.userExists) {
          for (const [i, field] of form.fields.entries()) {
            if (
              field.attending_field &&
              field.attending_field.field_type === 'file' &&
              field.value
            ) {
              const imageForm = new FormData();
              imageForm.append('file', field.value as string);
              // eslint-disable-next-line no-await-in-loop
              const response = await uploadFile(imageForm);

              form.fields[i] = {
                ...field,
                value: response.data.id,
              };
            }
          }
        }

        const exhibitor = parseInt(router.currentRoute.params.exhibitor, 10);

        createExhibitorInvitation({
          name: state.userExists ? undefined : basicForm.name,
          email: state.userExists ? preForm.email : basicForm.email,
          exhibitor,
          registration_form_fields: state.userExists ? undefined : form.fields,
          packages: state.packagesAdded,
        })
          .then(() => {
            state.loading = false;
            state.step = state.step + 1;
          })
          .catch((err) => {
            if (callback) {
              callback();
            }
            const errors = err.response.data;
            if (errors && errors.length > 0) {
              errors.forEach((error: string) => {
                store.commit('addPopup', {
                  message: error,
                  type: 'danger',
                  autohide: true,
                });
              });
            } else {
              store.commit('addPopup', {
                message: err.nessage,
                type: 'danger',
                autohide: true,
              });
            }
          });

        //   newAttendee(contextEvent.value, {
        //     name: basicForm.name,
        //     email: basicForm.email,
        //     fields: form.fields,
        //     sessions: form.sessions.filter((session) => !!session.value),
        //   })
        //     .then((response) => {
        //       const visitor = response.data;
        //       state.step = 3;
        //     })
        //     .catch((err) => {
        //       if (callback) {
        //         callback();
        //       }
        //       store.commit('addPopup', {
        //         type: 'danger',
        //         message: err.message,
        //         autohide: true,
        //       });
        //     });
      } else {
        state.loading = false;
      }
    };

    const handlePackageChange = (value: 'on' | 'off', i: number) => {
      if (value === 'on') {
        state.packagesAdded.push(state.packages[i].id);
      } else {
        state.packagesAdded = state.packagesAdded.filter(
          (packageId) => packageId !== state.packages[i].id,
        );
      }
    };

    const exhibitor = parseInt(router.currentRoute.params.exhibitor, 10);
    const event = parseInt(router.currentRoute.params.event, 10);
    // const { ticketTypeId } = router.currentRoute.params;

    // getTicketTypes(event).then((response) => {
    //   state.preInputs.ticket_type = {
    //     ...state.preInputs.ticket_type,
    //     value: typeof ticketTypeId === 'string' ? ticketTypeId : '',
    //     options: response.data.results.map((ticket) => ({
    //       value: `${ticket.id}`,
    //       label: ticket.name,
    //       checked: `${ticket.id}` === ticketTypeId,
    //     })),
    //   };
    // });

    buildPackagesTable();

    const packageForm = [false, false];

    if (contextEvent.value.id) {
      buildForm('onboarding');
    } else {
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setContextEvent') {
          buildForm('onboarding');
          unsubscribe();
        }
      });
    }

    return {
      contextEvent,
      translations,
      basicForm,
      form,
      disabled,
      state,
      preForm,
      packageForm,
      submit,
      assignTicket,
      fieldDependency,
      checkVisitor,
      assignAnother,
    };
  },
});
