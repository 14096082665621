import {
  IInput, ITextarea, ISelect, ICheckboxes, IRadios,
} from '@/components/form/index';

import { IModal } from '@/components';
import { IAttendingFieldsEntity } from '@/models/visitor';
import store from '@/store';
import { ITranslations } from '@/translations';

export const convertBridgeTitles = (title: string) => {
  const translations: ITranslations = store.getters.getTranslations;

  if (title === 'BridgeLogo') {
    return translations.exhibitors.onboarding.convert_titles.logo;
  }
  if (title === 'BridgeProfilePic') {
    return translations.exhibitors.onboarding.convert_titles.profile_pic;
  }
  if (title === 'BridgeEmbeddedVideo') {
    return translations.exhibitors.onboarding.convert_titles.embedded;
  }
  return title;
};

export const attendingInputs: Array<IInput | IRadios | ICheckboxes | ITextarea | ISelect> = [];

export const sessionGroupInputs: Array<IRadios | ICheckboxes> = [];

export const fields: Array<IAttendingFieldsEntity> = [];

export const stringsForName = [
  'name',
  'nombre',
  'nome',
];

export const modal: IModal = {
  isActive: false,
  isCard: true,
};
