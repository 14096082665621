




















































































import { defineComponent } from '@vue/composition-api';
import useContext, { IUseContext } from '@/composition/context';
import useForm from '@/composition/form';
import Input from '@/components/form/input.vue';
import Textarea from '@/components/form/textarea.vue';
import Radios from '@/components/form/radios.vue';
import Checkboxes from '@/components/form/checkboxes.vue';
import Select from '@/components/form/select.vue';
import Description from '@/components/form/description.vue';
import Modal from '@/components/modal.vue';
import Cropper from '@/components/form/cropper.vue';

// @ is an alias to /src
export default defineComponent({
  props: {
    form: {
      required: true,
      type: Object,
    },
  },
  components: {
    Textarea,
    Input,
    Radios,
    Checkboxes,
    Select,
    Modal,
    Cropper,
    Description,
  },
  setup(props) {
    const { translations } = useContext() as unknown as IUseContext;
    const { fieldDependency } = useForm();

    const confirmCrop = () => {
      props.form.modal.isActive = false;
    };

    const cancel = () => {
      props.form.modal.isActive = false;
    };

    const cancelCrop = () => {
      props.form.cropping = true;
      props.form.confirming = false;
    };

    return {
      confirmCrop,
      cancel,
      cancelCrop,
      fieldDependency,
      translations,
    };
  },
});
