import {
  computed,
  reactive,
} from '@vue/composition-api';
import { IAttendingFieldsEntity, SessionGroupsEntity } from '@/models/visitor';
import {
  ICheckboxes, IInput, IOption, IRadios,
} from '@/components/form';
import useContext from './context';
import useUtils from './utils';
import {
  attendingInputs, sessionGroupInputs, modal, stringsForName,
} from './index';
import { getAttendingAnswers } from '@/api/visitors';
import { IFieldAnswer } from '@/models/form';
import { getFileById } from '@/api/files';
import store from '@/store';
import { getEventInfo } from '@/api/events';
import { options } from 'preact';

const { contextEvent, translations, contextVisitor } = useContext();
const {
  firstLetterUpperCase, isEmail, isUrl, isJson,
} = useUtils();

const form = reactive({
  attendingInputs,
  sessionGroupInputs,
  sessions: [] as { field_type: string; value?: string }[],
  cropper: {
    image: '',
    onSelect: (blob: string) => {
      console.log(blob);
    },
    aspectRatio: null as any,
  },
  cropFieldId: 0,
  croppedFileName: '',
  cropSrc: '',
  cropping: false,
  confirming: false,
  fields: [] as IAttendingFieldsEntity[],
  sessionGroups: [] as SessionGroupsEntity[],
  loading: false,
  answers: [] as IFieldAnswer[],
  modal,
  invalid: undefined as boolean | undefined,
});

const onSelectCrop = (blob: string) => {
  if (form.fields) {
    const file = new File([blob], form.croppedFileName, { type: 'image/png' });
    const urlCreator = window.URL || window.webkitURL;
    form.cropSrc = urlCreator.createObjectURL(blob);
    form.fields = form.fields.map((field) => {
      if (field.id === form.cropFieldId) {
        return {
          ...field,
          value: file as any,
        };
      }
      return field;
    });
    form.cropping = false;
    form.confirming = true;

  }
};

form.cropper.onSelect = onSelectCrop;

const fieldDependency = (field: IAttendingFieldsEntity) => {
  if (form.fields && field.attending_field.dependence_field) {
    const fieldDepending = form.fields.find(
      (dField) => field.attending_field.dependence_field === dField.attending_field.id,
    );
    if (fieldDepending && field.attending_field.dependence_operator === '==') {
      if (typeof fieldDepending.value === 'object') {
        const checkedOption = fieldDepending.value.find(option => option.value === field.attending_field.dependence_value && option.checked);
        if (checkedOption) {
          return true;
        } else {
          return false;
        }
      } else if (fieldDepending && fieldDepending.value === field.attending_field.dependence_value) {
        return true;
      }
      return false;
    }
    return false;
  }
  return true;
};

const disabled = computed(() => {
  if (form.fields.length > 0) {
    const mandatoryLength = form.fields.filter(
      (field) => field.attending_field.mandatory && fieldDependency(field),
    ).length;
    return !(
      form.fields.filter(
        (field) => field.attending_field.mandatory &&
          fieldDependency(field) &&
          field.value &&
          field.value !== '',
      ).length === mandatoryLength
    );
  }
  return false;
});

const selectImage = (file: File) => {
  form.croppedFileName = file.name;
  if (form.fields) {
    const field = form.fields.find((field) => field.id === form.cropFieldId);
    if (field) {
      if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        if (field.attending_field.validation === 'square_image') {
          form.cropper = {
            ...form.cropper,
            aspectRatio: 1 / 1,
          };
        } else {
          form.cropper = {
            ...form.cropper,
            aspectRatio: null,
          };
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e && e.target && e.target.result) {
            form.cropper = { ...form.cropper, image: e.target.result as string };
            form.cropping = true;
            form.confirming = false;
            form.modal.isActive = true;
          }
        };
        reader.readAsDataURL(file);
      } else {
        const index = form.fields.indexOf(field);
        form.attendingInputs[index] = {
          ...form.attendingInputs[index],
          value: '',
          message: translations.value.common.file_not_image,
          error: true,
        };
      }
    }
  }
};
const buildForm = (use: 'onboarding' | 'form') => new Promise((resolve: (success: boolean) => void, reject: (err: any) => void) => {

  getEventInfo(contextEvent.value)
    .then((response) => {
      form.fields = response.data.fields ? response.data.fields.filter((field) => !field.attending_field.hide_from_self_user || use === 'onboarding') : [];
      form.sessionGroups = response.data.session_groups ? response.data.session_groups : [];

      if (form.sessionGroups && form.sessionGroups.length > 0) {
        form.sessionGroups.forEach((sessionGroup) => {
          if (sessionGroup.sessions) {
            const options: IOption[] = sessionGroup.sessions.map((session) => (session
              ? {
                value: session.attending_session.id,
                label: firstLetterUpperCase(session.attending_session.name),
                checked: false,
              }
              : ({} as any)));

            form.sessions.push({
              field_type: 'radio',
            });

            form.sessionGroupInputs.push({
              id: `visitor-${sessionGroup.id}`,
              name: `visitor_${sessionGroup.id}`,
              label: sessionGroup.description,
              type: sessionGroup.field_type,
              // We need the custom field to have a default value for the select
              message: sessionGroup.mandatory
                ? translations.value.common.mandatory_field
                : undefined,
              value: '',
              options,
            });
          }
        });
      }

      if (form.fields && form.fields.length > 0) {
        // Fields are sorted by custom order and the name field is removed if exists
        form.fields = form.fields
          .sort((prev, next) => {
            if (prev.order < next.order) {
              return -1;
            }
            return 1;
          })
          .filter(
            (field) => !stringsForName.includes(field.attending_field.title.toLowerCase()),
          );

        let optionsValues: Array<string>;

        form.fields.forEach((field, i) => {
          const attendingField = field.attending_field;
          const fieldType = attendingField.field_type;
          const fieldValidation = attendingField.validation;

          let title;
          if (field.attending_field.show_title && field.attending_field.title) {
            title = firstLetterUpperCase(field.attending_field.title);
          }

          if (
            (fieldType === 'select' || fieldType === 'radio' || fieldType === 'checkbox') &&
            attendingField.options
          ) {
            optionsValues = attendingField.options.split(',').map((string) => string.trim());
            const options: IOption[] = optionsValues.map((option) => ({
              value: option,
              label: firstLetterUpperCase(option),
              checked: false,
            }));
            const index = i;
            form.attendingInputs.push({
              id: `visitor-${field.id}`,
              name: `visitor_${field.id}`,
              label: title,
              type: field.attending_field.field_type,
              // We need the custom field to have a default value for the select
              disabled: use === 'form' && !field.attending_field.editable,
              onChange: (value: string | number) => clearInput(index, value),
              message: field.attending_field.mandatory
                ? translations.value.common.mandatory_field
                : undefined,
              value: options[0].value,
              options,
            });

          } else if (
            fieldType === 'description' ||
            fieldType === 'url' ||
            fieldType === 'label'
          ) {
            form.attendingInputs.push({
              id: `visitor-${field.id}`,
              name: `visitor_${field.id}`,
              label: title,
              type: fieldType,
              // For some reason, description's field value is saved in 'options'
              value: field.attending_field.options,
              disabled: use === 'form' && !field.attending_field.editable,
            });
          } else if (fieldType === 'file' && fieldValidation.includes('image')) {
            form.attendingInputs.push({
              id: `visitor-${field.id}`,
              name: `visitor_${field.id}`,
              label: title,
              type: field.attending_field.field_type,
              // For some reason, description's field value is saved in 'options'
              value: field.attending_field.options,
              disabled: use === 'form' && !field.attending_field.editable,

              message: field.attending_field.mandatory
                ? translations.value.common.mandatory_field
                : undefined,
              onFileSelect: (file: File) => {
                if (form.attendingInputs[i].error) {
                  form.attendingInputs[i] = {
                    ...form.attendingInputs[i],
                    error: false,
                    message: '',
                  };
                }
                form.cropFieldId = field.id;
                selectImage(file);
              },
            });
          } else {
            const index = i;
            form.attendingInputs.push({
              id: `visitor-${field.id}`,
              name: `visitor_${field.id}`,
              label: title,
              type: field.attending_field.field_type,
              disabled: use === 'form' && !field.attending_field.editable,

              message: field.attending_field.mandatory
                ? translations.value.common.mandatory_field
                : undefined,
              onInput: (value: string | number) => clearInput(index, value),
              value: '',
            });
          }
        });

        form.loading = false;
      }
      resolve(true);
    })
    .catch((err) => {
      console.log(err);
      form.loading = false;
      reject(err);
    });
});

const getAnswers = () => {
  if (contextVisitor.value.id) {
    form.loading = true;

    getAttendingAnswers(contextVisitor.value.id).then((response) => {
      form.answers = response.data.results;
      form.attendingInputs.forEach(async (input, i) => {
        const answer = form.answers.find((answer) => `visitor-${answer.event_attending_field}` === input.id);

        if (answer && form.fields) {
          if ((<ICheckboxes | IRadios>input).options) {
            let options: IOption[];
            if (answer.answer) {
              if (typeof answer.answer !== 'string' && answer.answer !== '' && isJson(answer.answer)) {
                options = JSON.parse(answer.answer) as IOption[];
              } else {
                options = (<IRadios>input).options.map((option) => ({
                  ...option,
                  checked: option.value === answer.answer,
                }));
              }
              const attendingInputs = form.attendingInputs.map(
                (attendingInput, index) => (i === index && answer.answer ? {
                  ...attendingInput,
                  value: answer.answer,
                  options,
                } : attendingInput),
              );

              form.attendingInputs = attendingInputs;

              form.fields[i].value = options;

              /*
                Inputs are redefined in this way rather than using the
                input variable so that vue is forced to update the template
              */
            }
          } else if ((<IInput>input).type === 'file') {
            // Get file from id
            form.attendingInputs[i] = {
              ...input,
            };
            form.fields[i].value = '';
            try {
              if (answer.answer && answer.answer !== '') {
                const fileResponse = await getFileById(answer.answer as any);
                const attendingInputs = form.attendingInputs.map(
                  (attendingInput, index) => (i === index && answer.answer ? {
                    ...attendingInput,
                    value: answer.answer,
                    file: fileResponse.data,
                  } : attendingInput),
                );
                form.attendingInputs = attendingInputs;
                // todo Refactor
                form.fields[i].value = answer.answer;
              }
            } catch (error) {
              // file not found
            }
          } else if (answer.answer) {
            const attendingInputs = form.attendingInputs.map(
              (attendingInput, index) => (i === index && answer.answer ? {
                ...attendingInput,
                value: answer.answer,
              } : attendingInput),
            );

            form.attendingInputs = attendingInputs;
            form.fields[i].value = answer.answer;
          }
        }
      });
      form.loading = false;
    }).catch((err) => {
      store.commit('addPopup', {
        type: 'danger',
        message: err.message,
        autohide: true,
      });
    });
  }
};

const validateForm = () => {
  form.invalid = false;

  form.fields.forEach((field, i) => {
    if ((field.attending_field.mandatory &&
      (!field.value || field.value === '') ||
      typeof field.value === 'object' &&
      noneChecked(field.value)) && fieldDependency(field)) {
      form.attendingInputs = form.attendingInputs.map((input, index) => {
        if (i === index) {
          return {
            ...input,
            error: true,
            message: translations.value.common.emptyFieldNotAllowed,
          }
        }
        return input
      })

      form.invalid = true;
    } else if (field.attending_field.validation === 'url') {
      if (!field.value || field.value === '') {
        if (field.attending_field.mandatory && fieldDependency(field)) {
          form.attendingInputs[i] = {
            ...form.attendingInputs[i],
            error: true,
            message: translations.value.common.emptyFieldNotAllowed,
          };
          form.invalid = true;
        }
      } else if (!isUrl(field.value as string)) {
        form.attendingInputs[i] = {
          ...form.attendingInputs[i],
          value: field.value as string,
          error: true,
          message: 'URL inválido',
        };
        form.invalid = true;
      }
    } else if (field.attending_field.validation === 'email') {
      if (!field.value || field.value === '') {
        if (field.attending_field.mandatory && fieldDependency(field)) {
          form.attendingInputs[i] = {
            ...form.attendingInputs[i],
            error: true,
            message: translations.value.common.emptyFieldNotAllowed,
          };
          form.invalid = true;
        }
      } else if (!isEmail(field.value as string)) {
        form.attendingInputs[i] = {
          ...form.attendingInputs[i],
          value: field.value as string,
          error: true,
          message: 'URL inválido',
        };
        form.invalid = true;
      }
    }
  });
};

const noneChecked = (options: IOption[]) => !options.some(option => option.checked);

const clearInput = (index: number, value: string | number) => {
  if (form.attendingInputs[index].error) {
    form.attendingInputs = form.attendingInputs.map(
      (attendingInput, i) => (i === index ? {
        ...attendingInput,
        value,
        error: false,
      } : attendingInput),
    );
  }
};

export default function useForm() {

  return {
    fieldDependency,
    buildForm,
    selectImage,
    clearInput,
    onSelectCrop,
    getAnswers,
    validateForm,
    disabled,
    form,
  };
}
